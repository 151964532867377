import React, { Component } from 'react'
import {cl, globs, constant,getTime,getTimeI, copyToClipboard,
  dateToDisplayDate,
} from '../../components/utils/utils';
import C18Input00 from '../../usa/components/C18Input00';
import C18Button00 from '../../usa/components/C18Button00';
import {getSuperUserFlags} from '../../usa/components/C18utils'
import Markdown from 'markdown-to-jsx'
import { wsTrans } from '../../usa/utils/utils';
import C18SubMenuHeader00 from '../../usa/components/C18SubMenuHeader00';

export default class ChatBotUI extends Component {
    constructor(props) {
      super(props);
      this.state = {
        message: "",
        answer: "",
        question: "",
        host: "https://faiza-ai.link4cloud.com",
        lochost: "http://localhost:8000",
        loading: false,
        trainingMode: false,
        chatHistory: [],
        type: undefined,
        chatHistory1: [
          {
              "question": "what is link4\n",
              "answer": "Link4 is a corporation that specializes in providing advanced environmental control solutions for the agricultural sector, particularly focusing on greenhouse operations. The company is known for its innovative technology that helps growers optimize their growing environments.\n\nKey features of Link4 include:\n\n1. **Pearl Controller**: One of their flagship products is the Pearl controller, an intelligent greenhouse environmental controller that integrates various systems such as irrigation, heating, cooling, lighting, and humidity control. This allows for efficient management of greenhouse conditions.\n\n2. **Cloud-Based Monitoring**: Link4 utilizes cloud technology to enable remote monitoring and management of greenhouse environments. This feature allows growers to access real-time data and control their systems from anywhere, enhancing operational efficiency.\n\n3. **User-Friendly Design**: The Pearl controller is designed to be flexible and easy to use, providing dynamic programming options that facilitate accurate greenhouse management.\n\n4. **Customer Support**: Link4 is committed to providing excellent customer service, with a well-trained support team available to assist users with their needs.\n\n5. **Target Market**: The company primarily serves small to midsize growers, offering cost-effective solutions that provide a high feature-to-price ratio.\n\nOverall, Link4 aims to improve the success of growers by providing intelligent control solutions that enhance plant growth and operational efficiency."
          },
          {
              "question": "what is par sensor\n",
              "answer": "A PAR (Photosynthetically Active Radiation) sensor is a device used to measure the amount of light available for photosynthesis in plants. It specifically measures light in the wavelength range of 400 to 700 nanometers, which is the spectrum of light that plants utilize for photosynthesis.\n\nKey features and functions of a PAR sensor include:\n\n1. **Light Measurement**: PAR sensors provide readings of light intensity, typically expressed in micromoles of photons per square meter per second (µmol/m²/s). This measurement helps growers understand how much light their plants are receiving.\n\n2. **Optimizing Growth Conditions**: By monitoring PAR levels, growers can adjust their lighting systems (such as grow lights) to ensure that plants receive the optimal amount of light for healthy growth and development.\n\n3. **Applications**: PAR sensors are commonly used in greenhouses, indoor farming, and research settings to assess light conditions and make informed decisions about lighting and plant care.\n\n4. **Integration with Control Systems**: Many modern environmental control systems, like the Pearl controller from Link4, can integrate PAR sensors to automate lighting adjustments based on real-time light measurements.\n\nOverall, PAR sensors are essential tools for growers to optimize light conditions for their plants, ultimately improving growth and yield."
          }
      ],
      feedBackQuestion: "",
      feedBackAnswer: "",
      pageType: 'Chat',
      documents: [],
      docText: "",
      showToast: false,
    
        // allQuestions: [],
        // alAnswers: []
      }
      this.subscribe_toKeyUp=globs.events.subscribe("keyUp",this.keyUp)
      this.username=globs?.user?.name||"You"
      this.menuItems = [
        {v:"Chat",e:"editReport",l:"viewReport",t:"Chat"},
      ]
      if ((getSuperUserFlags()&constant.SUPER_PRIVS_EDIT)!=0) this.menuItems.push(
        {v:"Documents",e:"editGraph",l:"viewGraph",t:"Documents"}
      )
      let av=globs?.user?.avatar
      // this.handleFeedback()
      if(av){
        this.path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      }else{
        this.path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
      }
      // hide scrollbar
      document.getElementById("sidebar-content").style = "overflow-y: hidden;";
      document.getElementById("sidebar-content").getElementsByClassName("inner")[0].style = "overflow-y: hidden;";
    }

    componentWillUnmount=()=>{
      this.subscribe_toKeyUp.remove()
      // add scrollbar back?
      document.getElementById("sidebar-content").style = "overflow-y: scroll;";
      document.getElementById("sidebar-content").getElementsByClassName("inner")[0].style = "overflow-y: scroll;";
    }

    keyUp=(e)=>{
      if (e.key == "Enter" && !this.state.trainingMode && this.state.pageType == "Chat") {
        this.handleSubmitPrompt(e)
      } else if (e.key == "Enter" && this.state.pageType == "Documents") {
        this.handleSubmitDocument(e) 
      }

    }
    // Function to handle input change
    handleInputChange = (event, pageType) => {
        // console.log("🚀 ~ ChatBotUI ~ event:", event, pageType)
        if (pageType == "Chat")
        this.setState({ message: event.target.value, question: event.target.value });
        else if (pageType == "Documents")
        this.setState({ docText: event.target.value });
    }
    handleTypeChange = async (val, ind,  question, answer) => {
      // cl("im in handletypechange")
      this.setState({ type: val, feedBackQuestion:question, feedBackAnswer: answer });
  //     await this.handleFeedback()
  // }
      const ch = this.state.chatHistory;
      const updatedCH = ch.map((c, i) => {
        if(i === ind){
          c.type = val
        }
        return c
      })
      this.setState({chatHistory: [...updatedCH]})

  //   handleFeedback= async () => {
      const { feedBackAnswer, feedBackQuestion, type } =  this.state
      const body = {
        question: question,
        answer: answer,
        type: val // 0 for thumbsdown n 1 for thumbs up
      };
      // cl("🚀 ~ ChatBotUI ~ handleFeedback= ~ body:", body)
      let feedback = await wsTrans("usa", {
        cmd: "cRest", uri: "/s/aiFeedback", method: "create",
        sessionId: globs.userData.session.sessionId, body

      })

    }

    handleSubmitPrompt = async (e) => {
        e.preventDefault() 
        let msg = this.state.message
        let quest = this.state.question
        let type = this.state.type
        this.setState({ 
          question: this.state.message,
          message: "",
          loading: true,
          chatHistory: [...this.state.chatHistory, {question: quest, answer: "thinking...", type: undefined}]
        })
        // call prompt api
        const apiUrl = this.state.trainingMode
        ? `${this.state.host}/api/v1/prompt/train` // API for Training Mode
        : `${this.state.host}/api/v1/llm/prompt`;  // Default API for Prompt Mode

        const resp = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message: msg, question: quest }),
          })
        const toastMsg = this.state.trainingMode ? "Response submitted" : ""
        const response = await resp.json()
        const { question, answer } = response
        const newAnswer = this.state.trainingMode ? msg : answer;
        // const newChatHistory = [...chatHistory, {question, answer: newAnswer, type: undefined}]
        // Update the chat history with the new answer
        this.setState(prevState => {
          const updatedChatHistory = [...prevState.chatHistory];
          // Update the last question with the received answer
          updatedChatHistory[updatedChatHistory.length - 1].answer = newAnswer;

          return {
            question: quest,
            answer: newAnswer,
            // trainingMode: prevState.trainingMode,
            trainingMode: false,
            message: "",
            loading: false,
            chatHistory: updatedChatHistory,
            showToast: true,
            toastMsg
          };
        });
        setTimeout(() => {
          this.setState({ showToast: false, toastMsg: ''});
        }, 3000);
    }

    handleSubmitDocument = async (e) => {
      e.preventDefault();
      const resp = await fetch(`${this.state.host}/api/v1/prompt/updateDoc`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: this.state.docText }),
        })
      // console.log("handleSubmitDocument", this.state.docText)
      this.setState({toastMsg: "Document updated", showToast: true});
      setTimeout(() => {
        this.setState({ showToast: false, toastMsg: ''});
      }, 3000);

    }

    handleImage = async e => {
      const files = e.target.files;
      if (!files[files.length - 1]) return
      if (files[files.length - 1].size > (104857600)) return

      const formData = new FormData();

      // open for multiple files
      // for(let i=0;i<files.length;i++){
      //   let file = files[i]
      //   let name=file.name
      //   let ext=name.substring(name.lastIndexOf("."))
      //   cl("🚀 ~ ChatBotUI ~ ext:", name)
      //   formData.append('file', file);
      // }
      formData.append('file', files[0]);

      try {
        // Example API call to upload files
        const response = await fetch(`${this.state.host}/api/v1/prompt/upload`, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        // cl('Upload Success:', data);
      } catch (error) {
        cl('Upload Error:', error);
      }
    }

    handleToggle= async e => {
      const tm = this.state.trainingMode
      this.setState({ trainingMode: !tm });

    }
    fetchDocuments = async () => {
      this.setState({ documentsLoading: true });
      try {
        const response = await fetch(`${this.state.host}/api/v1/prompt/documents`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        // console.log("🚀 ~ ChatBotUI ~ fetchDocuments= ~ data:", data)
        this.setState({ 
          documents: data.documents,
          documentsLoading: false 
        });
      } catch (error) {
        cl('Error fetching documents:', error);
        this.setState({ documentsLoading: false });
      }
    }
    onChange=async(o) => {
      this.setState({ pageType: o.pageType, docText: "" });
      if (o.pageType === 'Documents' && this.state.documents.length === 0) {
        await this.fetchDocuments();
      }
    }
    handleLoadDocument = (doc) => {
      // console.log("🚀 ~ ChatBotUI ~ doc:", doc)
      if (doc.name.match(/\.txt$/)) {
        this.setState({ docText: doc.text });
      }
    }
    render() {
      const {loading, question, answer, message, chatHistory1, pageType, documents} = this.state;
      // cl("🚀 ~ ChatBotUI ~ render state:", this.state)
     return (
      <>
      {this.state.showToast && this.state.toastMsg && (
        <div className="chatbot-toast-message">
          {this.state.toastMsg}
        </div>
      )}
      
      <div className="cbFormWrap">
        <C18SubMenuHeader00 parms={{
            items: this.menuItems,
            pageType: this.state.pageType,
            onChange: o=>this.onChange(o),
         }}/>
        {pageType === 'Chat' && (
        <form onSubmit={this.handleSubmitPrompt} method='POST'>
        

          <div>
            <div className="cbBox"> 

              <div className="cbBoxInHead">
                <h1>Link4 ChatBot <i>(Powered By AI)</i></h1>
                {/* <div className="cbCmdInst">
                  <span className="cbHIcon"><img src="/img/spark-white.png" /></span>
                  <span className="cbHt"><p>Link4 ChatBot for Pearl Controllers</p></span>
                </div> */}
                <div className="cbChat">
                  <div className="cbChat-fChild">
                    <div className="cbConversationWrap">
                    <div className="cbChat-sChild">
                      {/* <ul className="cbLoginUser">
                          <li><span><label>F</label></span><h3>You</h3></li>
                          <li><span></span><p>hi</p></li>
                      </ul> */}
                      <div className="cbHost">
                          <div className="cbHost-nIcon"><span><label><img src="/img/spark-white.png" /></label></span><h3>LinkBot</h3></div>
                          <div className="cbAns"><p>Hi! How may I help you?</p></div>
                      </div>
                      {this.state.chatHistory.map((item, index) => (
                        <div key={index}>
                          <div className="cbHost cbGuest">
                              <div className="cbHost-nIcon"><span><label><img src={this.path}/></label></span><h3>{this.username}</h3></div>
                              <div className="cbAns"><p>{item.question}</p></div>
                          </div>
                          <div className="cbHost">
                              <div className="cbHost-nIcon"><span><label><img src="/img/spark-white.png" /></label></span><h3>LinkBot</h3></div>
                              <div className="cbAns">{item.answer === "thinking..." ? (<p><span className='dot-loader'>...</span></p>) : <p><Markdown>{item.answer}</Markdown></p>}</div>
                              <div className="replyReaction">
                                {(item.type === 1 || item.type === undefined) && <span onClick={()=>this.handleTypeChange(1, index, item.question, item.answer)} className="thUp lBtn"><svg  viewBox="0 -960 960 960" fill={item.type ? "#1f8ded" : "#5f6368"}><path d="M720-120H280v-520l280-280 50 50q7 7 11.5 19t4.5 23v14l-44 174h258q32 0 56 24t24 56v80q0 7-2 15t-4 15L794-168q-9 20-30 34t-44 14Zm-360-80h360l120-280v-80H480l54-220-174 174v406Zm0-406v406-406Zm-80-34v80H160v360h120v80H80v-520h200Z"/></svg></span>}
                                {(item.type === 0 || item.type === undefined) && <span onClick={()=>this.handleTypeChange(0, index, item.question, item.answer)} className="thDn lBtn"><svg viewBox="0 -960 960 960"  fill={item.type === 0 ? "#1f8ded" : "#5f6368"}><path d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z"/></svg></span>}
                              </div>
                          </div>
                      </div>
                      ))}

                    </div> {/* end of cbChat-sChild */}
                    </div>
                    {/* end of cbConversationWrap */}
                    <div className="cbTextareStatic">
                    <div className="chArea-wrap"><textarea value={message}  onChange={(e) => this.handleInputChange(e, 'Chat') } className="cbtextArea" name="postContent"></textarea></div>
                    <div className="btn-area">
                      <ul>
                        {  loading ? 
                          <li className="ldrChBox"><img src="/img/spinner.gif"/></li> :
                          <li className="cbSend"><button></button></li>
                        }
                        <li className="edMode">
                          {/* attach file and edit mode code begin */}
                                
                        <div className="slide-switch">
                          <label className="switch">
                            <C18Input00
                              type="checkbox"
                              id="period-toggle"
                              name="period-toggle"
                              checked={this.state.trainingMode}
                              onChange={e=>this.handleToggle()}
                            />
                            <span className="slider round"></span>
                          </label>
                        <label className="switch-label" htmlFor="period-toggle">Training Mode</label>
                        <><div className="clearfloat"></div><br/></>
                        </div>
                        <div>
                          <C18Button00 type="button" className="floatleft material-icons-outlined" aria-label="upload" style={{
                            width:40, 
                            height:40, 
                          }}>attach_file
                          </C18Button00>
                        </div>
                        {/* <form className="file-upload-form" enctype="multipart/form-data"> */}
                          <C18Input00 id="/sidebar/ai/header/attach" type="file" multiple onChange={this.handleImage} 
                          style={{
                            position:"absolute", 
                            width:40, 
                            height:40, 
                            marginTop:0, 
                            marginLeft:-41,
                            zIndex:10,                   
                            opacity:0,
                            cursor: "pointer",
                          }}/>
                        {/* </form> */}

                        {/* attach file and edit mode code end */}
                        </li>
                    </ul>
                    </div> {/* chatSend, link, edit Btn */}
                    </div> {/* textarea and button wrap cbTextareStatic */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </form>
        )}
        {pageType === 'Documents' && (
          <div className="cbfileWrap">
          <div className="ui-doc cbFileTab">
          <h2 style={{marginTop: '3px'}}>Documents</h2>
          {documents.length === 0 ? (
            <p>No documents found.</p>
          ) : (
            <div className="cbFileInner">
              <form onSubmit={this.handleSubmitDocument} method='POST'>
              <ul className="document-list docTabWrap">
                {documents.map((doc, index) => (
                  <li key={index} className="document-item" onClick={()=>this.handleLoadDocument(doc)}>
                    <h3 className="docTab">{doc.name}</h3>
                      <div className="docActions">
                    {doc.path ? (
                      <a href={`${this.state.host}/api/v1/prompt/download/${doc.name}`} target="_blank" rel="noopener noreferrer">
                       <span className="viewIcon"><svg height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg></span>
                      </a>)
                      :
                      <span className="viewIcon"><svg height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg></span>

                      }
                       {/* <span className="delIcon"><svg height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg></span> */}
                       
                    {/*  </a> */}
                      
                      </div>
                    {/* )} */}
                  </li>
                ))}
              </ul>
              <div className="">
              <div className="chFtArea chArea-wrap"><textarea value={this.state.docText} onChange={ (e) => this.handleInputChange(e, 'Documents') } name="updateContent"></textarea></div>
              <div className="btn-area fModeBtn">
                      <ul>
                        {  loading ? 
                          <li className="ldrChBox"><img src="/img/spinner.gif"/></li> :
                          <li className="cbSend"><button></button></li>
                        }
                        <li className="edMode">
                          {/* attach file and edit mode code begin */}
                                
                
                        <div>
                          <C18Button00 type="button" className="floatleft material-icons-outlined" aria-label="upload" style={{
                            width:40, 
                            height:40, 
                          }}>attach_file
                          </C18Button00>
                        </div>
                        {/* <form className="file-upload-form" enctype="multipart/form-data"> */}
                          <C18Input00 id="/sidebar/ai/header/attach" type="file" multiple onChange={this.handleImage} 
                          style={{
                            position:"absolute", 
                            width:40, 
                            height:40, 
                            marginTop:0, 
                            marginLeft:-41,
                            zIndex:10,                   
                            opacity:0,
                            cursor: "pointer",
                          }}/>
                        {/* </form> */}

                        {/* attach file and edit mode code end */}
                        </li>
                    </ul>
                    </div> {/* chatSend, link, edit Btn */}
              </div>
              </form>
            </div>

          )}
          </div>
        </div>
      )}
        </div>
      </>
    )
  }
}
